import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper, { Navigation, Pagination, Autoplay, EffectFade , Thumbs } from 'swiper';


Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init({
    once: true
  });

  // more buttons
  $("#btnMore").hover(function () {
    if (window.innerWidth > 1280) {
      let isHovered = $(this).is(":hover");
      if (isHovered) {
        $(this).children('.more-links').stop().slideDown(300);
      } else {
        $(this).children('.more-links').stop().slideUp(300);
      }
    }
  });
  $("#btnMore").on('click', function () {
    if (window.innerWidth <= 1280) {
      if ($(this).hasClass('active')) {
        $(this).children('.more-links').stop().slideUp(300);
        $('#btnMore').removeClass('active');
      }else {
        $('#btnMore').addClass('active')
        $(this).children('.more-links').stop().slideDown(300);
      }
    }
  });
  $("#btnMore").focusout(function () {
    $(this).children('.more-links').stop().slideUp(300);
    $('#btnMore').removeClass('active');
  });

  //scroll top
  $('.back-to-top').click(function(event){
    $("html,body").animate({scrollTop: 0}, 1000);
  });

  // transparent header
  function headerScroll() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  headerScroll();
  $(window).on('scroll', function () {
    headerScroll();
  });

  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#header').toggleClass('active');
    $('.header-menu-outer').toggleClass('active');
  });

  $('.menu-link').click(function(e) {
      $(this).parent().toggleClass('active').siblings().removeClass('active');
  });

  // 首頁
  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: true,
    loop: true,
    speed: 1000,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
  });

  var indexTreatment = new Swiper('#indexTreatment', {
    slidesPerView: 1,
    speed: 500,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
    pagination: {
      el: ".swiper-pagination",
      type: "fraction",
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
    },
  });

  // 療程
  var treatmentBanner = new Swiper('#treatmentBanner', {
    slidesPerView: 1,
    speed: 500,
    navigation: {
      nextEl: ".swiper-button-next-custom",
      prevEl: ".swiper-button-prev-custom",
    },
  });

  // 文章內頁
  var postInnerThumbnail = new Swiper('#postInnerThumbnail', {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".swiper-button-next-sm",
      prevEl: ".swiper-button-prev-sm",
    },
  });
  var postInnerBanner = new Swiper('#postInnerBanner', {
    thumbs: {
      swiper: postInnerThumbnail,
    },
    slidesPerView: 1,
    speed: 500,
  });

  // 醫師popup
  $("[data-swal]").on('click', function(){
    event.preventDefault();
    let id = $(this).data('swal');
    let data = $("[data-id=" + id + "]").html();
    Swal_lg.fire({
      html: '<div class="swal-details">' + data+ '</div>',
    });
  });
});

